<template>
  <b-container fluid class="supply">
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap">
      <h2>Interogare stocuri critice</h2>
    </div>
    <b-row class="justify-content-center">
      <b-col col sm="8" md="6" lg="4">
        <b-form @submit.stop.prevent>
          <b-form-group>
            <b-input-group>
              <b-form-input id="lookupTerm" v-model="lookupTerm" trim debounce="500" autofocus
                placeholder="Caută după cod sau denumire produs (minim 4 caractere)" @submit.stop.prevent></b-form-input>
              <div>
                <b-button variant="primary" :disabled="xhrRequestRunning || lookupTerm?.length < 3" @click.prevent="performSearch">
                  <i class="fas fa-search" />

                  <span class="d-none d-lg-inline"> Caută</span>
                </b-button>
                <b-button v-if="searchLookupPerformed" variant="danger" :disabled="xhrRequestRunning" @click.prevent="onSearchReset">
                  <i class="fas fa-times" />
                  <span class="d-none d-lg-inline"> Resetează</span>
                </b-button>
              </div>
            </b-input-group>
          </b-form-group>
        </b-form>
      </b-col>
    </b-row>
    <br />

    <b-alert :show="criticalLookupInProgress || searchLookupInProgress" variant="primary">Se cauta stocuri
      critice</b-alert>

    <b-alert :show="criticalLookupPerformed && !searchLookupPerformed && !searchLookupInProgress" variant="success">Au fost gasite
      {{
        criticalItems.length
      }}
      rezultate</b-alert>

    <b-alert :show="criticalLookupFailed || searchLookupFailed" variant="danger">Cautarea a esuat</b-alert>

    <b-alert :show="!criticalLookupPerformed && !criticalLookupInProgress && searchLookupPerformed" variant="success">Au fost gasite
      {{
        allProducts.filter(p => selectCriticalSuppliers ? p.codFurnizorImplicit === selectCriticalSuppliers.codFurnizorImplicit : true).length
      }}
      rezultate <span v-if="isTableVisible"> pentru termenul "{{ lastLookupTerm }}" </span></b-alert>

    <div v-if="searchLookupInProgress || searchLookupPerformed">
      <div class="critical">
        <CriticalInventoryFilters @saveFilters="handleSaveCriticalFilters" @resetFilters="handleResetCriticalFilters"
      :critical-items="allProducts" :suppliers="suppliers" :interval-range="intervalRangeCritical" />
        <b-table hover small thead-class="text-small" tbody-class="text-small" tfoot-class="text-small" :items="allProducts.filter(p => selectCriticalSuppliers ? p.codFurnizorImplicit === selectCriticalSuppliers.codFurnizorImplicit : true)" :fields="tableFieldsCritical" :busy.sync="searchLookupInProgress" class="relative">
          <template #thead-top="tableFieldsCritical" style="height: 0">
            <b-tr style="position: relative">
              <input v-model="allSearchItemsSelected" type="checkbox" @click="selectAllSearchItems" class="selectAllSearch" />
            </b-tr>
          </template>
          <template v-slot:cell(checkbox)="row">
            <input  type="checkbox" v-model="row.item.select" @click="toggleSearchItem(row.item.codProdus)" />
          </template>
          <template v-slot:cell(productCategoryName)="data">
            <Highlighter class="my-highlight" highlightClassName="highlight" :searchWords="highlightValue"
              :autoEscape="true" :textToHighlight="data.value" />
          </template>
          <template v-slot:cell(catalogItemCode)="data">
            <Highlighter class="my-highlight" highlightClassName="highlight" :searchWords="highlightValue"
              :autoEscape="true" :textToHighlight="data.value" />
          </template>
          <template v-slot:cell(productName)="data">
            <Highlighter class="my-highlight" highlightClassName="highlight" :searchWords="highlightValue"
              :autoEscape="true" :textToHighlight="data.value" />
          </template>
        </b-table>
      </div>
    </div>

    <div v-else>
      <div class="critical">
        <CriticalInventoryFilters @saveFilters="handleSaveCriticalFilters" @resetFilters="handleResetCriticalFilters"
      :critical-items="criticalItems" :suppliers="suppliers" :interval-range="intervalRangeCritical" />
        <b-table hover small thead-class="text-small" tbody-class="text-small" tfoot-class="text-small"
          :busy.sync="criticalLookupInProgress" :items="criticalItems" :fields="tableFieldsCritical">
          <template #thead-top="tableFieldsCritical">
            <b-tr style="position: relative">
              <input v-model="allCriticalItemsSelected" type="checkbox" @click="selectAllCriticalItems" class="selectAll" />
            </b-tr>
          </template>
          <template v-slot:cell(checkbox)="row">
            <input type="checkbox" v-model="row.item.select" @click="toggleCriticalItem(row.item.codProdus)" />
          </template>
          <template v-slot:cell(productCategoryName)="data">
            <Highlighter class="my-highlight" highlightClassName="highlight" :searchWords="highlightValue"
              :autoEscape="true" :textToHighlight="data.value" />
          </template>
          <template v-slot:cell(catalogItemCode)="data">
            <Highlighter class="my-highlight" highlightClassName="highlight" :searchWords="highlightValue"
              :autoEscape="true" :textToHighlight="data.value" />
          </template>
          <template v-slot:cell(productName)="data">
            <Highlighter class="my-highlight" highlightClassName="highlight" :searchWords="highlightValue"
              :autoEscape="true" :textToHighlight="data.value" />
          </template>
        </b-table>
      </div>
    </div>
  </b-container>
</template>

<script>
import Highlighter from 'vue-highlight-words';
import { mapState, mapActions } from 'vuex';
import CriticalInventoryFilters from '../components/CriticalInventoryFilters.vue';

export default {
  name: 'CriticalInventory',
  components: {
    Highlighter,
    CriticalInventoryFilters,
  },
  data() {
    return {
      allCriticalItemsSelected: false,
      allSearchItemsSelected: false,
      lookupTerm: '',
      lastLookupTerm: null,
      criticalLookupInProgress: true,
      searchLookupInProgress: false,
      criticalLookupPerformed: false,
      searchLookupPerformed: false,
      criticalLookupFailed: false,
      searchLookupFailed: false,
      tableFieldsCritical: [
        {
          key: 'checkbox',
          label: '...............',
        },
        {
          key: 'denumireFurnizorImplicit',
          label: 'Furnizor',
          sortable: true,
        },
        {
          key: 'barCode',
          label: 'Cod EAN',
          sortable: true,
        },
        {
          key: 'productName',
          label: 'Denumire produs',
          sortable: true,
        },
        {
          key: 'stocInitial',
          label: 'Stoc initial',
          sortable: true,
        },

        {
          key: 'cantitateIntrari',
          label: 'Cantitate intrari',
          sortable: true,
        },

        {
          key: 'cantitateIesiri',
          label: 'Cantitate iesiri',
          sortable: true,
        },

        {
          key: 'stocFinal',
          label: 'Stoc final',
          sortable: true,
        },
        {
          key: 'soldFinal',
          label: 'Sold final',
          sortable: true,
        },
        {
          key: 'speedRotation',
          label: 'Viteza de rotatie',
          sortable: true,
        },
        {
          key: 'durationSupply',
          label: 'Zile stoc',
          sortable: true,
        },
        {
          key: 'timpAprovizionare',
          label: 'Timp Aprovizionare',
          sortable: true,
        },
        {
          key: 'daysStockDurationSupply',
          label: 'Zile stoc - timp aprovizionare',
          sortable: true,
        },

      ],
      allProducts: [],
      criticalItems: [],
      selectCriticalSuppliers: null,
      dateRangeFilterCritical: {
        startDate: new Date(new Date().setDate(new Date().getDate() - 45))
          .toISOString()
          .split('T')[0],
        endDate: new Date().toISOString().split('T')[0],
      },
      intervalRangeCritical: 45,
      suppliers: [],
    };
  },
  computed: {
    isTableVisible() {
      return true;
    },
    // eslint-disable-next-line no-mixed-operators
    highlightValue() {
      return (
        (this.lastLookupTerm && [
          this.lastLookupTerm,
          ...this.lastLookupTerm.split(' ').filter((s) => !!s),
        ])
        || []
      );
    },
    ...mapState(['xhrRequestRunning']),
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => vm.performCriticalStockLookup());
  },
  async beforeRouteUpdate(to, from, next) {
    try {
      this.handleSaveCriticalFilters({ dateRangeFilter: this.dateRangeFilterCritical });
      await this.performCriticalStockLookup();
    } finally {
      next();
    }
  },
  methods: {
    ...mapActions(['performProcurementSupply']),
    selectAllCriticalItems() {
      if (this.criticalItems.some((item) => item.select === false)) {
        this.criticalItems.forEach((item) => {
          // eslint-disable-next-line no-param-reassign
          item.select = true;
        });
      } else {
        this.criticalItems.forEach((item) => {
          // eslint-disable-next-line no-param-reassign
          item.select = false;
        });
      }
    },
    selectAllSearchItems() {
      if (this.allProducts.some((item) => item.select === false)) {
        this.allProducts.forEach((item) => {
          // eslint-disable-next-line no-param-reassign
          item.select = true;
        });
      } else {
        this.allProducts.forEach((item) => {
          // eslint-disable-next-line no-param-reassign
          item.select = false;
        });
      }
    },

    toggleCriticalItem(codProdus) {
      this.criticalItems.find((item) => item.codProdus === codProdus).select = !this.criticalItems.find(
        (item) => item.codProdus === codProdus,
      ).select;
    },

    toggleSearchItem(codProdus) {
      this.allProducts.find((item) => item.codProdus === codProdus).select = !this.allProducts.find(
        (item) => item.codProdus === codProdus,
      ).select;
    },

    async performCriticalStockLookup() {
      this.criticalLookupPerformed = false;
      this.criticalLookupInProgress = true;

      const endDate = new Date(this.dateRangeFilterCritical.endDate);
      endDate.setHours(23, 59, 59, 59);
      const startDate = new Date(this.dateRangeFilterCritical.startDate);
      startDate.setHours(0, 0, 0, 0);

      let result;
      try {
        result = (
          await this.performProcurementSupply({
            startDate,
            endDate,
            productCategoryIds: [],
            supplierCodes: this.selectCriticalSuppliers
              ? [this.selectCriticalSuppliers.codFurnizorImplicit]
              : [],
          })
        ).map((item) => {
          const rotationSpeed = (item.stocInitial + item.cantitateIntrari - item.stocFinal) / this.intervalRangeCritical;
          let durationSupply;
          if (rotationSpeed === 0) {
            durationSupply = 999;
          } else {
            durationSupply = item.stocFinal / rotationSpeed <= 0 ? 0 : item.stocFinal / rotationSpeed;
          }
          const durationSupplyDays = durationSupply - item.timpAprovizionareZile <= 0
            ? 0
            : durationSupply - item.timpAprovizionareZile;
          let color;
          if (durationSupply >= 0 && durationSupply < 1) {
            color = 'danger';
          } else if (durationSupply >= 1 && durationSupply < 15) {
            color = 'orange';
          } else if (durationSupply >= 15 && durationSupply <= 30) {
            color = 'warning';
          }

          const necesarStoc = rotationSpeed.toFixed(2) * 60;
          const necesarNet = necesarStoc - item.stocFinal;

          const product = {
            produsInternalID: item.produsInternalID,
            codProdus: item.codProdus,
            productName: item.denumire,
            productCategoryId: item.categorieProdusID,
            productCategoryName: item.denumireCategorieProdus,
            barCode: item.codBare,
            codFurnizorImplicit: item.codFurnizorImplicit,
            denumireFurnizorImplicit: item.denumireFurnizorImplicit,
            stocInitial: item.stocInitial,
            soldInitial: item.soldInitial,
            cantitateIntrari: item.cantitateIntrari,
            valoareIntrari: item.valoareIntrari,
            cantitateIesiri: item.cantitateIesiri,
            valoareIesiri: item.valoareIesiri,
            stocFinal: item.stocFinal,
            soldFinal: item.soldFinal,
            speedRotation: rotationSpeed.toFixed(2),
            durationSupply: durationSupply.toFixed(2),
            daysStockDurationSupply: durationSupplyDays.toFixed(2),
            select: false,
            necesarZile: 60,
            necesarStoc: necesarStoc.toFixed(2),
            necesarNet: necesarNet.toFixed(2),
            _rowVariant: color,
            codProdusFurnizor: item.codProdusLaFurnizor,
            timpAprovizionare: item.timpAprovizionareZile,
            pretLaFurnizor: item.pretLaFurnizor,
            codValutaPretLaFurnizor: item.codValutaPretLaFurnizor,
            unitateComanda: item.unitateComanda,
          };
          const supplier = {
            codFurnizorImplicit: item.codFurnizorImplicit,
            denumireFurnizorImplicit: item.denumireFurnizorImplicit,
          };

          if (!this.suppliers.some((i) => i.codFurnizorImplicit === supplier.codFurnizorImplicit)) {
            this.suppliers.push(supplier);
          }

          return product;
        });
        if (this.selectCriticalSuppliers == null) {
          result = result.filter((item) => item.speedRotation > 0 && item.durationSupply < 30);
        }
        result.sort((a, b) => parseFloat(a.durationSupply) - parseFloat(b.durationSupply));

        this.criticalLookupPerformed = true;
      } catch (err) {
        this.criticalLookupInProgress = false;
        this.criticalLookupFailed = true;
        // eslint-disable-next-line no-console
        console.error(err);
        result = [];
      }
      this.criticalItems = result;
      this.criticalLookupInProgress = false;
      this.allCriticalItemsSelected = false;
      this.allSearchItemsSelected = false;
    },

    async performSearch() {
      // reset lookup term
      this.lastLookupTerm = this.lookupTerm;
      this.lookupTerm = '';

      this.searchLookupPerformed = false;
      this.searchLookupInProgress = true;
      const endDate = new Date(this.dateRangeFilterCritical.endDate);
      endDate.setHours(23, 59, 59, 59);
      const startDate = new Date(this.dateRangeFilterCritical.startDate);
      startDate.setHours(0, 0, 0, 0);

      let result;
      try {
        result = (
          await this.performProcurementSupply({
            startDate,
            endDate,
            productCategoryIds: [],
            supplierCodes: [],
          })
        ).map((item) => {
          const rotationSpeed = (item.stocInitial + item.cantitateIntrari - item.stocFinal) / this.intervalRangeCritical;
          let durationSupply;
          if (rotationSpeed === 0) {
            durationSupply = 999;
          } else {
            durationSupply = item.stocFinal / rotationSpeed <= 0 ? 0 : item.stocFinal / rotationSpeed;
          }
          const durationSupplyDays = durationSupply - item.timpAprovizionareZile <= 0
            ? 0
            : durationSupply - item.timpAprovizionareZile;
          let color;
          if (durationSupply >= 0 && durationSupply < 1) {
            color = 'danger';
          } else if (durationSupply >= 1 && durationSupply < 15) {
            color = 'orange';
          } else if (durationSupply >= 15 && durationSupply <= 30) {
            color = 'warning';
          }

          const necesarStoc = rotationSpeed.toFixed(2) * 60;
          const necesarNet = necesarStoc - item.stocFinal;

          const product = {
            produsInternalID: item.produsInternalID,
            codProdus: item.codProdus,
            productName: item.denumire,
            productCategoryId: item.categorieProdusID,
            productCategoryName: item.denumireCategorieProdus,
            barCode: item.codBare,
            codFurnizorImplicit: item.codFurnizorImplicit,
            denumireFurnizorImplicit: item.denumireFurnizorImplicit,
            stocInitial: item.stocInitial,
            soldInitial: item.soldInitial,
            cantitateIntrari: item.cantitateIntrari,
            valoareIntrari: item.valoareIntrari,
            cantitateIesiri: item.cantitateIesiri,
            valoareIesiri: item.valoareIesiri,
            stocFinal: item.stocFinal,
            soldFinal: item.soldFinal,
            speedRotation: rotationSpeed.toFixed(2),
            durationSupply: durationSupply.toFixed(2),
            daysStockDurationSupply: durationSupplyDays.toFixed(2),
            select: false,
            necesarZile: 60,
            necesarStoc: necesarStoc.toFixed(2),
            necesarNet: necesarNet.toFixed(2),
            _rowVariant: color,
            codProdusFurnizor: item.codProdusLaFurnizor,
            timpAprovizionare: item.timpAprovizionareZile,
            pretLaFurnizor: item.pretLaFurnizor,
            codValutaPretLaFurnizor: item.codValutaPretLaFurnizor,
            unitateComanda: item.unitateComanda,
          };
          const supplier = {
            codFurnizorImplicit: item.codFurnizorImplicit,
            denumireFurnizorImplicit: item.denumireFurnizorImplicit,
          };

          if (!this.suppliers.some((i) => i.codFurnizorImplicit === supplier.codFurnizorImplicit)) {
            this.suppliers.push(supplier);
          }

          return product;
        });
        result = result.filter((item) => item.productName?.toLowerCase().includes(this.lastLookupTerm.toLowerCase()) || item.barCode?.toLowerCase().includes(this.lastLookupTerm.toLowerCase()) || item.codProdus?.toLowerCase().includes(this.lastLookupTerm.toLowerCase()));
        this.allProducts = result;
        this.searchLookupInProgress = false;
        this.searchLookupPerformed = true;
        this.allCriticalItemsSelected = false;
        this.allSearchItemsSelected = false;
      } catch (err) {
        this.searchLookupInProgress = false;
        this.searchLookupPerformed = true;
        // eslint-disable-next-line no-console
        console.error(err);
        this.allProducts = [];
      }
    },

    async onSearchReset() {
      this.lastLookupTerm = null;
      this.lookupTerm = '';
      this.searchLookupPerformed = false;
      this.searchLookupInProgress = false;
      this.allProducts = [];
    },
    handleSaveCriticalFilters(filters) {
      this.selectCriticalSuppliers = filters.selectSuppliers;
      this.dateRangeFilterCritical = {
        startDate: filters.dateRangeFilter[0] || this.dateRangeFilterCritical.startDate,
        endDate: filters.dateRangeFilter[1] || this.dateRangeFilterCritical.endDate,
      };
      const endDateObject = new Date(this.dateRangeFilterCritical.endDate);
      endDateObject.setHours(23, 59, 59, 59);
      const startDateObject = new Date(this.dateRangeFilterCritical.startDate);
      startDateObject.setHours(0, 0, 0, 0);
      const endTimestamp = endDateObject.getTime();
      const startTimestamp = startDateObject.getTime();
      const interval = endTimestamp - startTimestamp;
      const intervalDays = interval / (1000 * 3600 * 24);
      this.intervalRangeCritical = Math.round(intervalDays) || this.intervalRangeCritical;
      this.performCriticalStockLookup();
    },
    handleResetCriticalFilters(filters) {
      this.selectCriticalSuppliers = null;
      this.dateRangeFilterCritical = {
        startDate: new Date(new Date().setDate(new Date().getDate() - 45))
          .toISOString()
          .split('T')[0],
        endDate: new Date().toISOString().split('T')[0],
      };
      this.intervalRangeCritical = 45;
      this.criticalItems = filters.criticalItems;
      this.performCriticalStockLookup();
    },
  },
};
</script>

<style>
.supply .table th,
.supply .table td {
  vertical-align: middle;
}

.supply .table-orange,
.supply .table-orange>th,
.supply .table-orange>td {
  background-color: orange;
}

.supply .table.b-table>thead>tr>[aria-sort="none"],
.supply .table.b-table>tfoot>tr>[aria-sort="none"],
.supply .table.b-table>thead>tr>[aria-sort="ascending"],
.supply .table.b-table>tfoot>tr>[aria-sort="ascending"] {
  background-image: url("/img/backgrounds/sort.svg");
}

.supply .table.b-table>thead>tr>[aria-sort="descending"],
.supply .table.b-table>tfoot>tr>[aria-sort="descending"] {
  background-image: url("https://visualpharm.com/assets/73/Sort%20Up-595b40b65ba036ed117d4ba1.svg");
}

.supply th[aria-colindex="1"] {
  color: white;
}

.supply .relative {
  position: relative;
}

.supply .selectAll {
  position: absolute;
  top: 15px;
  left: 24px;
}

.supply .selectAllSearch {
  position: absolute;
  top: 8px;
  left: 24px;
}

.supply td[aria-colindex="8"],
.supply td[aria-colindex="10"],
.supply td[aria-colindex="12"],
.supply td[aria-colindex="13"] {
  font-weight: bold;
}
</style>
