<template>
  <b-modal ref="generateOrderLatentModal" id="generate-order-latent" title="Genereaza comanda" size="xl" scrollable
    cancel-title="Renunță" ok-title="Generează" @cancel="closeModal" @ok="generateOrder">
    <div class="filter-row">
      <vue-json-to-csv :json-data="items.map(item => {
        item.productName = formatInput(item.productName);
        item.codProdusFurnizor = item.codProdusFurnizor == null ? '' : item.codProdusFurnizor;
        item.codProdus = item.codProdus == null ? '' : item.codProdus;
        return item;
      })" csv-title="Bon comanda" :csv-headings="tableFieldsCritic" :labels="csvHeadings"
        @success="val => isGeneratingCsv = false">
        <button ref="generateLatentOrderButton" class="btn py-2 px-4 btn-primary filterButton" @click="isGeneratingCsv = true"
          :disabled="isGeneratingCsv" hidden>
          Exporta tabel
        </button>
      </vue-json-to-csv>
    </div>

    <b-table hover small thead-class="text-small" tbody-class="text-small" tfoot-class="text-small" :items="items"
      :fields="tableFieldsCritic">

    </b-table>
  </b-modal>
</template>

<script>
import VueJsonToCsv from 'vue-json-to-csv';

export default {
  name: 'GenerateOrderLatent',
  props: ['items'],
  components: {
    VueJsonToCsv,
  },
  computed: {
    tableFieldsCritic() {
      return [
        { key: 'codProdus', label: 'Cod' },
        { key: 'codProdusFurnizor', label: 'Cod furnizor' },
        { key: 'codBare', label: 'Cod EAN' },
        { key: 'productName', label: 'Denumire' },
        { key: 'stocFinal', label: 'Stoc curent' },
        { key: 'iesiri', label: 'Iesiri/' },
        { key: 'intervalRange', label: 'Nr. zile analiza' },
        { key: 'speedRotation', label: 'VMZ' },
        { key: 'unitateComanda', label: 'Unitate Comanda' },
        { key: 'pretLaFurnizor', label: 'Pret la furnizor' },
      ];
    },
  },

  data() {
    return {
      necesarZileCustom: null,
      csvHeadings: {
        codProdus: { title: 'Cod' },
        codProdusFurnizor: { title: 'Cod furnizor' },
        codBare: { title: 'Cod EAN' },
        productName: { title: 'Denumire' },
        stocFinal: { title: 'Stoc' },
        iesiri: { title: 'Iesiri/' },
        intervalRange: { title: 'Nr. zile analiza' },
        speedRotation: { title: 'VMZ' },
        pretLaFurnizor: { title: 'Pret la furnizor' },
      },
      isGeneratingCsv: false,
    };
  },

  methods: {
    applyNecesarDays() {
      this.items = this.items.map((item) => {
        // eslint-disable-next-line no-param-reassign
        item.necesarZile = this.necesarZileCustom;
        return item;
      });
      this.necesarZileCustom = null;
    },
    formatInput(input) {
      return input.replace(/"/g, '');
    },
    closeModal() {
      this.$refs.generateOrderLatentModal.hide();
    },
    showModal() {
      this.$refs.generateOrderLatentModal.show();
    },
    generateOrder() {
      this.$refs.generateLatentOrderButton.click();
    },
  },
};
</script>

<style>
thead tr th {
  border-top: none !important;
}
</style>
