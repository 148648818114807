<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div class="filter">
    <div class="filter-row" style="gap: 10px; padding: 20px 0">
      <multiselect v-model="selectSuppliers" placeholder="Furnizor" selected-label="Selectat"
        label="denumireFurnizorImplicit" track-by="denumireFurnizorImplicit" select-label="Alege cu Enter"
        deselect-label="Elimină cu Enter" :options="multiselectOptions" :allow-empty="true" :multiple="false"
        :hide-selected="false" class="select" @input="saveFilters">
        <template slot="label" slot-scope="{option}">
          <div class="d-flex">
            <span>{{ option.denumireFurnizorImplicit }}</span>
          </div>
        </template>
      </multiselect>

      <date-picker v-model="dateRangeFilter" :placeholder="dateRangeFilter.startDate + ' ~ ' + dateRangeFilter.endDate
        " :shortcuts="datePickerShortcuts" range input-class="form-control" class="w-100" value-type="YYYY-MM-DD"
        :clearable="false" :disabled-date="disableFutureDates" @input="saveFilters">
      </date-picker>
      <p class="w-50 m-0">Perioada de referinta {{ intervalRange }} zile</p>
    </div>

    <div class="filter-buttons">
      <button v-if="resetFiltersShow" class="btn py-2 px-4 btn-danger filterButton" v-on:click="resetFilters">
        <i class="fas fa-times" /> &nbsp; Resetează filtrele
      </button>
      <button class="btn py-2 px-4 btn-primary filterButton" v-if="showGenerateButton"
        @click="showGenerateOrderModal(orderItems)">
        Genereaza bon comanda
      </button>

    </div>
    <generate-order-critical ref="generateOrderCriticalModal" :items="orderItems" />
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import GenerateOrderCritical from './modals/GenerateOrderCritical.vue';

export default {
  name: 'CriticalInventoryFilters',
  components: {
    DatePicker,
    GenerateOrderCritical,
  },
  props: ['criticalItems', 'suppliers', 'intervalRange'],
  data() {
    return {
      dateRangeFilter: {
        startDate: new Date(new Date().setDate(new Date().getDate() - this.intervalRange))
          .toISOString()
          .split('T')[0],
        endDate: new Date().toISOString().split('T')[0],
      },
      selectSuppliers: null,
      datePickerShortcuts: [
        {
          text: 'Ultimele 2 luni',
          onClick: () => [this.getFirstDayOfGivenMonthsAgo(1), new Date()],
        },
        {
          text: 'Ultimele 3 luni',
          onClick: () => [this.getFirstDayOfGivenMonthsAgo(2), new Date()],
        },
        {
          text: 'Doar luna curenta',
          onClick: () => [this.getFirstDayOfGivenMonthsAgo(0), new Date()],
        },
        {
          text: 'Anul curent',
          onClick: () => [new Date(new Date().getFullYear(), 0, 1), new Date()],
        },
        {
          text: 'Anul trecut',
          onClick: () => [
            new Date(new Date().getFullYear() - 1, 0, 1),
            new Date(),
          ],
        },
        {
          text: 'Ultimii 10 ani',
          onClick: () => [
            new Date(new Date().getFullYear() - 9, 0, 1),
            new Date(),
          ],
        },
      ],
      resetFiltersShow: false,
    };
  },
  computed: {
    showGenerateButton() {
      return this.criticalItems.some((item) => item.select === true);
    },
    orderItems() {
      const filteredItems = this.criticalItems.filter((e) => e.select === true);
      if (!filteredItems || filteredItems.length === 0) return [];
      return [...filteredItems.map((item) => {
        const product = {
          codProdus: item.codProdus,
          codProdusFurnizor: item.codProdusFurnizor,
          codBare: item.barCode,
          productName: item.productName,
          stocFinal: item.stocFinal,
          iesiri: item.cantitateIesiri,
          intervalRange: this.intervalRange,
          speedRotation: item.speedRotation,
          necesarStoc: item.necesarStoc,
          necesarNet: item.necesarNet,
          necesarZile: item.necesarZile,
          unitateComanda: item.unitateComanda,
          pretLaFurnizor: `${item.pretLaFurnizor.toFixed(2)} ${item.codValutaPretLaFurnizor}`,
          pretTotal: `${(item.necesarNet * item.pretLaFurnizor).toFixed(2)} ${item.codValutaPretLaFurnizor}`,
        };
        return product;
      }), {
        codProdus: 'Total',
        codProdusFurnizor: '',
        codBare: '',
        productName: '',
        stocFinal: '',
        iesiri: '',
        intervalRange: '',
        speedRotation: '',
        necesarStoc: '',
        necesarZile: '',
        unitateComanda: '',
        necesarNet: filteredItems.reduce((acc, item) => acc + parseFloat(item.necesarNet), 0).toFixed(2),
        pretLaFurnizor: `${filteredItems.reduce((acc, item) => acc + parseFloat(item.necesarNet) * parseFloat(item.pretLaFurnizor), 0)} ${filteredItems[0].codValutaPretLaFurnizor}`,
        pretTotal: `${filteredItems.reduce((acc, item) => acc + parseFloat(item.necesarNet) * parseFloat(item.pretLaFurnizor), 0)} ${filteredItems[0].codValutaPretLaFurnizor}`,
      }];
    },
    multiselectOptions() { return this.suppliers.map((supplier) => supplier); },
  },
  methods: {
    showGenerateOrderModal(items) {
      this.$nextTick(() => this.$refs.generateOrderCriticalModal.showModal({ items }));
    },
    disableFutureDates(date) {
      return date > new Date();
    },
    getFirstDayOfGivenMonthsAgo(monthsAgo) {
      const date = new Date();
      date.setDate(1);
      date.setMonth(date.getMonth() - monthsAgo);

      return date;
    },

    saveFilters() {
      this.$emit('saveFilters', {
        selectSuppliers: this.selectSuppliers,
        dateRangeFilter: this.dateRangeFilter,
      });
      this.resetFiltersShow = true;
    },
    resetFilters() {
      this.resetFiltersShow = false;
      this.selectSuppliers = null;
      this.dateRangeFilter = {
        startDate: new Date(new Date().setDate(new Date().getDate() - 30))
          .toISOString()
          .split('T')[0],
        endDate: new Date().toISOString().split('T')[0],
      };
      this.$emit('resetFilters', {
        selectSuppliers: null,
        dateRangeFilter: {
          startDate: new Date(new Date().setDate(new Date().getDate() - 30))
            .toISOString()
            .split('T')[0],
          endDate: new Date().toISOString().split('T')[0],
        },
        criticalItems: [],
      });
    },
  },
};
</script>

<style scoped>
.filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.multiselect .multiselect__tags {
  overflow: visible !important;
  min-height: 31px !important;
  min-width: 100% !important;
}

.mx-datepicker-range {
  width: 100% !important;
  display: block !important;
}

.filter-row {
  display: flex;
  align-items: center;
  justify-items: end;
  /* justify-content: space-between; */
  width: 45%;
  gap: 20px;
}

.filter-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

@media only screen and (max-width: 700px) {
  .filter {
    flex-direction: column;
    gap: 0;
    margin-bottom: 20px;
  }

  .filter-row {
    width: 100%;
  }
}

.multiselect .multiselect__content-wrapper {
  width: 100% !important;
}
</style>

<style>
.filter .mx-datepicker-sidebar+.mx-datepicker-content {
  margin-left: 140px !important;
}

.filter .mx-datepicker-sidebar {
  width: inherit !important;
}

.filter .multiselect .multiselect__tags {
  overflow: inherit !important;
}

.filter .multiselect__select {
  right: -6px;
}
</style>
