<template>
  <b-container fluid class="supply">
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap">
      <h2>Interogare stocuri latente</h2>
    </div>
    <b-row class="justify-content-center">
      <b-col col sm="8" md="6" lg="4">
        <b-form @submit.stop.prevent>
          <b-form-group>
            <b-input-group>
              <b-form-input id="lookupTerm" v-model="lookupTerm" trim debounce="500" autofocus
                placeholder="Caută după cod sau denumire produs (minim 4 caractere)" @submit.stop.prevent />
              <div>
                <b-button variant="primary" :disabled="xhrRequestRunning || lookupTerm?.length < 3" @click.prevent="performSearch">
                  <i class="fas fa-search" />

                  <span class="d-none d-lg-inline"> Caută</span>
                </b-button>
                <b-button  v-if="searchLookupPerformed" variant="danger" :disabled="xhrRequestRunning" @click.prevent="onSearchReset">
                  <i class="fas fa-times" />
                  <span class="d-none d-lg-inline">&nbsp;Resetează</span>
                </b-button>
              </div>
            </b-input-group>
          </b-form-group>
        </b-form>
      </b-col>
    </b-row>
    <br />

    <b-alert :show="latentLookupInProgress || searchLookupInProgress" variant="primary">Se cauta stocuri latente</b-alert>

    <b-alert :show="latentLookupPerformed && !searchLookupPerformed && !searchLookupInProgress" variant="success">Au fost gasite
      {{
        latentItems.length
      }}
      rezultate</b-alert>

    <b-alert :show="latentLookupFailed || searchLookupFailed" variant="danger">Cautarea a esuat</b-alert>

    <b-alert :show="!latentLookupPerformed && !latentLookupInProgress && searchLookupPerformed" variant="success">Au fost gasite
      {{
         allProducts.filter(p => selectLatentSuppliers ? p.codFurnizorImplicit === selectLatentSuppliers.codFurnizorImplicit : true).length
      }}
      rezultate <span v-if="isTableVisible"> pentru termenul "{{ lastLookupTerm }}" </span></b-alert>

    <div v-if="searchLookupInProgress || searchLookupPerformed">
      <LatentInventoryFilters @saveFilters="handleSaveLatentFilters" :latentItems="this.allProducts"
      :suppliers="this.suppliers" :intervalRange="this.intervalRangeLatent" @resetFilters="handleResetLatentFilters" />
      <b-table hover small thead-class="text-small" tbody-class="text-small" tfoot-class="text-small" :items="allProducts.filter(p => selectLatentSuppliers ? p.codFurnizorImplicit === selectLatentSuppliers.codFurnizorImplicit : true)" :fields="tableFieldsLatent" :busy.sync="searchLookupInProgress" class="relative">
        <template #thead-top="tableFieldsLatent" style="height: 0">
          <b-tr style="position: relative">
            <input v-model="allSearchItemsSelected" type="checkbox" @click="selectAllSearchItems" class="selectAllSearch" />
          </b-tr>
        </template>
        <template v-slot:cell(checkbox)="row">
          <input type="checkbox" v-model="row.item.select" @click="toggleSearchItem(row.item.codProdus)" />
        </template>
        <template v-slot:cell(productCategoryName)="data">
          <Highlighter class="my-highlight" highlightClassName="highlight" :searchWords="highlightValue"
            :autoEscape="true" :textToHighlight="data.value" />
        </template>
        <template v-slot:cell(catalogItemCode)="data">
          <Highlighter class="my-highlight" highlightClassName="highlight" :searchWords="highlightValue"
            :autoEscape="true" :textToHighlight="data.value" />
        </template>
        <template v-slot:cell(productName)="data">
          <Highlighter class="my-highlight" highlightClassName="highlight" :searchWords="highlightValue"
            :autoEscape="true" :textToHighlight="data.value" />
        </template>
      </b-table>
    </div>

    <div v-else>
      <LatentInventoryFilters @saveFilters="handleSaveLatentFilters" :latentItems="this.latentItems"
      :suppliers="this.suppliers" :intervalRange="this.intervalRangeLatent" @resetFilters="handleResetLatentFilters" />
      <b-table hover small thead-class="text-small" tbody-class="text-small" tfoot-class="text-small"
        :busy.sync="latentLookupInProgress" :items="latentItems" :fields="tableFieldsLatent" class="relative">

        <template #thead-top="tableFieldsLatent" style="height: 0">
          <b-tr style="position: relative">
            <input v-model="allLatentItemsSelected" type="checkbox" @click="selectAllLatentItems" class="selectAll" />
          </b-tr>
        </template>
        <template v-slot:cell(checkbox)="row">
          <input type="checkbox" v-model="row.item.select" @click="toggleLatentItem(row.item.codProdus)" />
        </template>
        <template v-slot:cell(productCategoryName)="data">
          <Highlighter class="my-highlight" highlightClassName="highlight" :searchWords="highlightValue"
            :autoEscape="true" :textToHighlight="data.value" />
        </template>
        <template v-slot:cell(catalogItemCode)="data">
          <Highlighter class="my-highlight" highlightClassName="highlight" :searchWords="highlightValue"
            :autoEscape="true" :textToHighlight="data.value" />
        </template>
        <template v-slot:cell(productName)="data">
          <Highlighter class="my-highlight" highlightClassName="highlight" :searchWords="highlightValue"
            :autoEscape="true" :textToHighlight="data.value" />
        </template>
      </b-table>
    </div>
  </b-container>
</template>

<script>
import Highlighter from 'vue-highlight-words';
import { mapState, mapActions } from 'vuex';
import LatentInventoryFilters from '../components/LatentInventoryFilters.vue';

export default {
  name: 'LatentInventory',
  components: {
    Highlighter,
    LatentInventoryFilters,
  },
  data() {
    return {
      allLatentItemsSelected: false,
      allSearchItemsSelected: false,
      lookupTerm: '',
      lastLookupTerm: null,
      latentLookupInProgress: true,
      searchLookupInProgress: false,
      latentLookupPerformed: false,
      searchLookupPerformed: false,
      latentLookupFailed: false,
      searchLookupFailed: false,
      tableFieldsLatent: [
        {
          key: 'checkbox',
          label: '...............',
        },
        {
          key: 'denumireFurnizorImplicit',
          label: 'Furnizor',
          sortable: true,
        },
        {
          key: 'barCode',
          label: 'Cod EAN',
          sortable: true,
        },
        {
          key: 'productName',
          label: 'Denumire produs',
          sortable: true,
        },
        {
          key: 'stocInitial',
          label: 'Stoc initial',
          sortable: true,
        },
        {
          key: 'soldInitial',
          label: 'Sold initial',
          sortable: true,
        },
        {
          key: 'cantitateIntrari',
          label: 'Cantitate intrari',
          sortable: true,
        },
        {
          key: 'valoareIntrari',
          label: 'Valoare intrari',
          sortable: true,
        },
        {
          key: 'cantitateIesiri',
          label: 'Cantitate iesiri',
          sortable: true,
        },
        {
          key: 'valoareIesiri',
          label: 'Valoare iesiri',
          sortable: true,
        },
        {
          key: 'stocFinal',
          label: 'Stoc final',
          sortable: true,
        },
        {
          key: 'soldFinal',
          label: 'Sold final',
          sortable: true,
        },
        {
          key: 'speedRotation',
          label: 'Viteza de rotatie',
          sortable: true,
        },
        {
          key: 'durationSupply',
          label: 'Zile stoc',
          sortable: true,
        },
      ],
      allProducts: [],
      latentItems: [],
      selectLatentSuppliers: null,
      dateRangeFilterLatent: {
        startDate: new Date(new Date().setDate(new Date().getDate() - 60))
          .toISOString()
          .split('T')[0],
        endDate: new Date().toISOString().split('T')[0],
      },
      intervalRangeLatent: 60,
      suppliers: [],
    };
  },
  computed: {
    isTableVisible() {
      return true;
    },
    // eslint-disable-next-line no-mixed-operators
    highlightValue() {
      return (
        (this.lastLookupTerm && [
          this.lastLookupTerm,
          ...this.lastLookupTerm.split(' ').filter((s) => !!s),
        ])
        || []
      );
    },
    ...mapState(['xhrRequestRunning']),
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.performLatentStockLookup();
    });
  },
  async beforeRouteUpdate(to, from, next) {
    try {
      this.handleSaveLatentFilters({ dateRangeFilter: this.dateRangeFilterLatent });
      await this.performLatentStockLookup();
    } finally {
      next();
    }
  },
  methods: {
    ...mapActions(['performProcurementSupply']),
    selectAllLatentItems() {
      if (this.latentItems.some((item) => item.select === false)) {
        this.latentItems.forEach((item) => {
          // eslint-disable-next-line no-param-reassign
          item.select = true;
        });
      } else {
        this.latentItems.forEach((item) => {
          // eslint-disable-next-line no-param-reassign
          item.select = false;
        });
      }
    },
    selectAllSearchItems() {
      if (this.allProducts.some((item) => item.select === false)) {
        this.allProducts.forEach((item) => {
          // eslint-disable-next-line no-param-reassign
          item.select = true;
        });
      } else {
        this.allProducts.forEach((item) => {
          // eslint-disable-next-line no-param-reassign
          item.select = false;
        });
      }
    },

    toggleLatentItem(codProdus) {
      this.latentItems.find((item) => item.codProdus === codProdus).select = !this.latentItems.find(
        (item) => item.codProdus === codProdus,
      ).select;
    },

    toggleSearchItem(codProdus) {
      this.allProducts.find((item) => item.codProdus === codProdus).select = !this.allProducts.find(
        (item) => item.codProdus === codProdus,
      ).select;
    },

    async performLatentStockLookup() {
      this.latentLookupPerformed = false;
      this.latentLookupInProgress = true;
      const endDate = new Date(this.dateRangeFilterLatent.endDate);
      endDate.setHours(23, 59, 59, 59);
      const startDate = new Date(this.dateRangeFilterLatent.startDate);
      startDate.setHours(0, 0, 0, 0);

      let result;
      try {
        result = (
          await this.performProcurementSupply({
            startDate,
            endDate,
            productCategoryIds: [],
            supplierCodes: this.selectLatentSuppliers
              ? [this.selectLatentSuppliers.codFurnizorImplicit]
              : [],
          })
        ).map((item) => {
          const rotationSpeed = (item.stocInitial + item.cantitateIntrari - item.stocFinal) / this.intervalRangeLatent;
          let durationSupply;
          if (rotationSpeed === 0) {
            durationSupply = 999;
          } else {
            durationSupply = item.stocFinal / rotationSpeed <= 0 ? 0 : item.stocFinal / rotationSpeed;
          }
          const durationSupplyDays = durationSupply - item.timpAprovizionareZile <= 0
            ? 0
            : durationSupply - item.timpAprovizionareZile;
          let color;
          if (rotationSpeed >= 0 && rotationSpeed < 1) {
            color = 'danger';
          } else if (rotationSpeed >= 1 && rotationSpeed < 2.5) {
            color = 'orange';
          } else if (rotationSpeed >= 2.5 && rotationSpeed <= 5) {
            color = 'warning';
          }

          const necesarStoc = rotationSpeed.toFixed(2) * 60;
          const necesarNet = necesarStoc - item.stocFinal;

          const product = {
            produsInternalID: item.produsInternalID,
            codProdus: item.codProdus,
            productName: item.denumire,
            productCategoryId: item.categorieProdusID,
            productCategoryName: item.denumireCategorieProdus,
            barCode: item.codBare,
            codFurnizorImplicit: item.codFurnizorImplicit,
            denumireFurnizorImplicit: item.denumireFurnizorImplicit,
            stocInitial: item.stocInitial,
            soldInitial: item.soldInitial,
            cantitateIntrari: item.cantitateIntrari,
            valoareIntrari: item.valoareIntrari,
            cantitateIesiri: item.cantitateIesiri,
            valoareIesiri: item.valoareIesiri,
            stocFinal: item.stocFinal,
            soldFinal: item.soldFinal,
            speedRotation: rotationSpeed.toFixed(2),
            durationSupply: durationSupply.toFixed(2),
            daysStockDurationSupply: durationSupplyDays.toFixed(2),
            select: false,
            necesarZile: 60,
            necesarStoc: necesarStoc.toFixed(2),
            necesarNet: necesarNet.toFixed(2),
            _rowVariant: color,
            codProdusFurnizor: item.codProdusLaFurnizor,
            timpAprovizionare: item.timpAprovizionareZile,
            pretLaFurnizor: item.pretLaFurnizor,
            codValutaPretLaFurnizor: item.codValutaPretLaFurnizor,
          };
          const supplier = {
            codFurnizorImplicit: item.codFurnizorImplicit,
            denumireFurnizorImplicit: item.denumireFurnizorImplicit,
          };

          if (!this.suppliers.some((i) => i.codFurnizorImplicit === supplier.codFurnizorImplicit)) {
            this.suppliers.push(supplier);
          }

          return product;
        });
        this.allProducts = this.allProducts.length === 0 ? result : this.allProducts;
        if (this.selectLatentSuppliers == null) {
          result = result.filter(
            (item) => item.speedRotation >= 0 && item.speedRotation < 5 && item.stocInitial > 0,
          );
        }
        result.sort((a, b) => parseFloat(a.speedRotation) - parseFloat(b.speedRotation));
        this.latentLookupPerformed = true;
      } catch (err) {
        this.latentLookupInProgress = false;
        this.latentLookupFailed = true;
        // eslint-disable-next-line no-console
        console.error(err);
        result = [];
      }
      this.latentItems = result;
      this.latentLookupInProgress = false;
      this.allLatentItemsSelected = false;
      this.allSearchItemsSelected = false;
    },

    async performSearch() {
      this.lastLookupTerm = this.lookupTerm;
      this.lookupTerm = '';

      this.searchLookupPerformed = false;
      this.searchLookupInProgress = true;
      const endDate = new Date(this.dateRangeFilterLatent.endDate);
      endDate.setHours(23, 59, 59, 59);
      const startDate = new Date(this.dateRangeFilterLatent.startDate);
      startDate.setHours(0, 0, 0, 0);

      let result;
      try {
        result = (
          await this.performProcurementSupply({
            startDate,
            endDate,
            productCategoryIds: [],
            supplierCodes: [],
          })
        ).map((item) => {
          const rotationSpeed = (item.stocInitial + item.cantitateIntrari - item.stocFinal) / this.intervalRangeLatent;
          let durationSupply;
          if (rotationSpeed === 0) {
            durationSupply = 999;
          } else {
            durationSupply = item.stocFinal / rotationSpeed <= 0 ? 0 : item.stocFinal / rotationSpeed;
          }
          const durationSupplyDays = durationSupply - item.timpAprovizionareZile <= 0
            ? 0
            : durationSupply - item.timpAprovizionareZile;
          let color;
          if (rotationSpeed >= 0 && rotationSpeed < 1) {
            color = 'danger';
          } else if (rotationSpeed >= 1 && rotationSpeed < 2.5) {
            color = 'orange';
          } else if (rotationSpeed >= 2.5 && rotationSpeed <= 5) {
            color = 'warning';
          }

          const necesarStoc = rotationSpeed.toFixed(2) * 60;
          const necesarNet = necesarStoc - item.stocFinal;

          const product = {
            produsInternalID: item.produsInternalID,
            codProdus: item.codProdus,
            productName: item.denumire,
            productCategoryId: item.categorieProdusID,
            productCategoryName: item.denumireCategorieProdus,
            barCode: item.codBare,
            codFurnizorImplicit: item.codFurnizorImplicit,
            denumireFurnizorImplicit: item.denumireFurnizorImplicit,
            stocInitial: item.stocInitial,
            soldInitial: item.soldInitial,
            cantitateIntrari: item.cantitateIntrari,
            valoareIntrari: item.valoareIntrari,
            cantitateIesiri: item.cantitateIesiri,
            valoareIesiri: item.valoareIesiri,
            stocFinal: item.stocFinal,
            soldFinal: item.soldFinal,
            speedRotation: rotationSpeed.toFixed(2),
            durationSupply: durationSupply.toFixed(2),
            daysStockDurationSupply: durationSupplyDays.toFixed(2),
            select: false,
            necesarZile: 60,
            necesarStoc: necesarStoc.toFixed(2),
            necesarNet: necesarNet.toFixed(2),
            _rowVariant: color,
            codProdusFurnizor: item.codProdusLaFurnizor,
            timpAprovizionare: item.timpAprovizionareZile,
            pretLaFurnizor: item.pretLaFurnizor,
            codValutaPretLaFurnizor: item.codValutaPretLaFurnizor,
          };
          const supplier = {
            codFurnizorImplicit: item.codFurnizorImplicit,
            denumireFurnizorImplicit: item.denumireFurnizorImplicit,
          };

          if (!this.suppliers.some((i) => i.codFurnizorImplicit === supplier.codFurnizorImplicit)) {
            this.suppliers.push(supplier);
          }

          return product;
        });
        result = result.filter((item) => item.productName?.toLowerCase().includes(this.lastLookupTerm.toLowerCase()) || item.barCode?.toLowerCase().includes(this.lastLookupTerm.toLowerCase()) || item.codProdus?.toLowerCase().includes(this.lastLookupTerm.toLowerCase()));
        this.allProducts = result;
        this.searchLookupInProgress = false;
        this.searchLookupPerformed = true;
        this.allLatentItemsSelected = false;
        this.allSearchItemsSelected = false;
      } catch (err) {
        this.searchLookupInProgress = false;
        this.searchLookupPerformed = true;
        // eslint-disable-next-line no-console
        console.error(err);
        result = [];
      }
    },

    async onSearchReset() {
      this.lastLookupTerm = null;
      this.lookupTerm = '';
      this.searchLookupPerformed = false;
      this.searchLookupInProgress = false;
      this.allProducts = [];
    },

    handleSaveLatentFilters(filters) {
      this.selectLatentSuppliers = filters.selectSuppliers;
      this.dateRangeFilterLatent = {
        startDate: filters.dateRangeFilter[0] || this.dateRangeFilterLatent.startDate,
        endDate: filters.dateRangeFilter[1] || this.dateRangeFilterLatent.endDate,
      };
      const endDateObj = new Date(this.dateRangeFilterLatent.endDate);
      endDateObj.setHours(23, 59, 59, 59);
      const startDateObj = new Date(this.dateRangeFilterLatent.startDate);
      startDateObj.setHours(0, 0, 0, 0);
      const endTimestamp = endDateObj.getTime();
      const startTimestamp = startDateObj.getTime();
      const interval = endTimestamp - startTimestamp;
      const intervalDays = interval / (1000 * 3600 * 24);
      this.intervalRangeLatent = Math.round(intervalDays) || this.intervalRangeLatent;
      this.performLatentStockLookup();
    },
    handleResetLatentFilters(filters) {
      this.selectLatentSuppliers = null;
      this.dateRangeFilterLatent = {
        startDate: new Date(new Date().setDate(new Date().getDate() - 60))
          .toISOString()
          .split('T')[0],
        endDate: new Date().toISOString().split('T')[0],
      };
      this.intervalRangeLatent = 60;
      this.latentItems = filters.latentItems;
      this.performLatentStockLookup();
    },
  },
};
</script>

<style>
.supply .table th,
.supply .table td {
  vertical-align: middle;
}

.supply .table-orange,
.supply .table-orange>th,
.supply .table-orange>td {
  background-color: orange;
}

.supply .table.b-table>thead>tr>[aria-sort="none"],
.supply .table.b-table>tfoot>tr>[aria-sort="none"],
.supply .table.b-table>thead>tr>[aria-sort="ascending"],
.supply .table.b-table>tfoot>tr>[aria-sort="ascending"] {
  background-image: url("/img/backgrounds/sort.svg");
}

.supply .table.b-table>thead>tr>[aria-sort="descending"],
.supply .table.b-table>tfoot>tr>[aria-sort="descending"] {
  background-image: url("https://visualpharm.com/assets/73/Sort%20Up-595b40b65ba036ed117d4ba1.svg");
}

.supply .relative {
  position: relative;
}

.supply th[aria-colindex="1"] {
  color: white;
}

.supply .relative {
  position: relative;
}

.supply .selectAll {
  position: absolute;
  top: 15px;
  left: 24px;
}

.supply .selectAllSearch {
  position: absolute;
  top: 8px;
  left: 24px;
}

.supply td[aria-colindex="8"],
.supply td[aria-colindex="10"],
.supply td[aria-colindex="12"],
.supply td[aria-colindex="13"] {
  font-weight: bold;
}
</style>
