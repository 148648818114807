var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{ref:"generateOrderCriticalModal",attrs:{"id":"generate-order-critical","title":"Genereaza comanda","size":"xl","scrollable":"","cancel-title":"Renunță","ok-title":"Generează"},on:{"cancel":_vm.closeModal,"ok":_vm.generateOrder}},[_c('div',{staticClass:"filter-row"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center mb-3"},[_c('b-form-input',{staticClass:"select w-25",attrs:{"type":"number","placeholder":"Necesar zile pentru toate produsele","min":"1"},model:{value:(_vm.necesarZileCustom),callback:function ($$v) {_vm.necesarZileCustom=$$v},expression:"necesarZileCustom"}}),_c('b-button',{staticClass:"px-3",attrs:{"size":"sm","variant":"primary"},on:{"click":_vm.applyNecesarDays}},[_vm._v(" Aplica ")])],1),_c('vue-json-to-csv',{attrs:{"json-data":_vm.items.map(item => {
      item.productName = _vm.formatInput(item.productName);
      item.codProdusFurnizor = item.codProdusFurnizor == null ? '' : item.codProdusFurnizor;
      item.codProdus = item.codProdus == null ? '' : item.codProdus;
      item.unitateComanda = item.unitateComanda == null ? '' : item.unitateComanda;
      return item;
    }),"csv-title":"Bon comanda","csv-headings":_vm.tableFieldsCritic,"labels":_vm.csvHeadings},on:{"success":val => _vm.isGeneratingCsv = false}},[_c('button',{ref:"generateCriticalOrderButton",staticClass:"btn py-2 px-4 btn-primary filterButton",attrs:{"disabled":_vm.isGeneratingCsv,"hidden":""},on:{"click":function($event){_vm.isGeneratingCsv = true}}},[_vm._v(" Exporta tabel ")])])],1),_c('b-table',{attrs:{"hover":"","small":"","thead-class":"text-small","tbody-class":"text-small","tfoot-class":"text-small","items":_vm.items,"fields":_vm.tableFieldsCritic}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }