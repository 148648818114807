<template>
  <b-modal ref="generateOrderCriticalModal" id="generate-order-critical" title="Genereaza comanda" size="xl" scrollable
    cancel-title="Renunță" ok-title="Generează" @cancel="closeModal" @ok="generateOrder">
    <div class="filter-row">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <b-form-input type="number" v-model="necesarZileCustom" placeholder="Necesar zile pentru toate produsele"
          class="select w-25" min="1" />

        <b-button size="sm" variant="primary" class="px-3" @click="applyNecesarDays">
          Aplica
        </b-button>
      </div>
      <vue-json-to-csv :json-data="items.map(item => {
        item.productName = formatInput(item.productName);
        item.codProdusFurnizor = item.codProdusFurnizor == null ? '' : item.codProdusFurnizor;
        item.codProdus = item.codProdus == null ? '' : item.codProdus;
        item.unitateComanda = item.unitateComanda == null ? '' : item.unitateComanda;
        return item;
      })" csv-title="Bon comanda" :csv-headings="tableFieldsCritic" :labels="csvHeadings"
        @success="val => isGeneratingCsv = false">
        <button ref="generateCriticalOrderButton" class="btn py-2 px-4 btn-primary filterButton" @click="isGeneratingCsv = true"
          :disabled="isGeneratingCsv" hidden>
          Exporta tabel
        </button>
      </vue-json-to-csv>
    </div>

    <b-table hover small thead-class="text-small" tbody-class="text-small" tfoot-class="text-small" :items="items"
      :fields="tableFieldsCritic">

    </b-table>
  </b-modal>
</template>

<script>
import VueJsonToCsv from 'vue-json-to-csv';

export default {
  name: 'GenerateOrderCritical',
  props: ['items'],
  components: {
    VueJsonToCsv,
  },
  computed: {
    tableFieldsCritic() {
      return [
        { key: 'codProdus', label: 'Cod' },
        { key: 'codProdusFurnizor', label: 'Cod furnizor' },
        { key: 'codBare', label: 'Cod EAN' },
        { key: 'productName', label: 'Denumire' },
        { key: 'stocFinal', label: 'Stoc curent' },
        { key: 'iesiri', label: 'Iesiri/' },
        { key: 'intervalRange', label: 'Nr. zile analiza' },
        { key: 'speedRotation', label: 'VMZ' },
        { key: 'necesarStoc', label: 'Necesar stoc' },
        { key: 'necesarZile', label: 'Zile stoc acoperite' },
        { key: 'necesarNet', label: 'Necesar net  (Necesar stoc - Stoc curent)' },
        { key: 'unitateComanda', label: 'Unitate comanda' },
        { key: 'pretLaFurnizor', label: 'Pret la furnizor' },
        { key: 'pretTotal', label: 'Pret total' },
      ];
    },
  },
  data() {
    return {
      necesarZileCustom: null,
      csvHeadings: {
        codProdus: { title: 'Cod' },
        codProdusFurnizor: { title: 'Cod furnizor' },
        codBare: { title: 'Cod EAN' },
        productName: { title: 'Denumire' },
        stocFinal: { title: 'Stoc' },
        iesiri: { title: 'Iesiri/' },
        intervalRange: { title: 'Nr. zile analiza' },
        speedRotation: { title: 'VMZ' },
        necesarStoc: { title: 'Necesar stoc' },
        necesarZile: { title: 'Zile stoc acoperite' },
        necesarNet: { title: 'Necesar net  (Necesar stoc - Stoc curent)' },
        unitateComanda: { title: 'Unitate comanda' },
        pretLaFurnizor: { title: 'Pret la furnizor' },
        pretTotal: { title: 'Pret total' },
      },
      isGeneratingCsv: false,
    };
  },
  methods: {
    applyNecesarDays() {
      this.items = this.items.map((item) => {
        // eslint-disable-next-line no-param-reassign
        item.necesarZile = this.necesarZileCustom;
        return item;
      });
      this.necesarZileCustom = null;
    },
    stringifyValue(value) {
      return JSON.stringify(value);
    },
    formatInput(input) {
      return input.replace(/"/g, '');
    },
    closeModal() {
      this.$refs.generateOrderCriticalModal.hide();
    },
    showModal() {
      this.$refs.generateOrderCriticalModal.show();
    },
    generateOrder() {
      this.$refs.generateCriticalOrderButton.click();
    },
  },
};

</script>
